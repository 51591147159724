import styles from '../../styles/features/header.module.css'
import scLogo from '../../assets/scLogoCircle.png'

function Header({}) {
  return (
    <div className={styles.main}>
      <img 
        src={scLogo} 
        className={styles.logo}
        alt={'sailfish'}
      />
      <div className={styles.mottoContainer}>
        <text className={styles.motto}>
          Tools to Catch
        </text>
        <text className={styles.motto}>
          The Perfect Day
        </text>
      </div>
    </div>
  )
}
export default Header
